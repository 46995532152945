import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
              idx={15}
            />
          </h1>
          <p>
            Hi! I am Ziyang Huang. I am a passionate, proactive and diligent
            software engineer. I graduated from the University of Melbourne
            as a Master's Computer Science on December 2023. Now I am looking
            for an job opportunity in the industry.
          </p>

          <p>
            During my study, I have been working on several projects, including
            a web application, a machine learning project
            for a research group, and a group project for a mobile application.
            I have been working on these projects with a team and have been
            responsible for the front-end and back-end development. 
          </p>

          <p>
            My skillsets include CSS, Html, JavaScript, TypeScript, React, Java, Python,
            SpringBoot, MongoDB, MySQL, Git, 
            Pytorch, TensorFlow, OpenCV and so on. If you have any interest in
            contacting me for any job opportunities,
            please reach out to me through the contact page :)
            <p>Looking forward to chat with you.</p>
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faAngular} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
