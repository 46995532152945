import React from 'react'
import Loader from 'react-loaders'

const Projects = () => {
  return (
    <>
        <div className="container projects-page">

        </div>
        <Loader type="pacman" />
    </>
  )
}

export default Projects